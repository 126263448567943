import {createSelector, createSlice} from "@reduxjs/toolkit";
import dayjs from "@shared/services/dayjs";
import {registrationsActions, registrationsSelectors} from "./registrations";
import {OFFLINE_MODE} from "@shared/utils/offlineModeUtilities";
import {getSessionElementsFromListInSlot, slotNumberString} from "@utils/agendaUtilities";
import {personName} from "@shared/utils/utilities";
import {createCustomEntityAdapter} from "@shared/utils/api/customEntityAdapter";
import {teamsSelectors} from "./teams";
import {getSessionSubscription} from "@utils/registrationsUtilities";
import {getSlotsStartAndEnd} from "@utils/slotsUtilities";
import {placesSelectors} from "./places";
import {stewardsSelectors} from "./stewards";
import {categoriesSelectors} from "./categories";
import {currentProjectSelectors} from "./currentProject";
import {viewSelectors} from "./view";
import {persistEntityInBackend} from "@shared/utils/api/persistEntityInBackend";
import {displaySessionsInconsistenciesNotifications} from "@utils/features/displaySessionsInconsistenciesNotifications";
import {removeEntityInBackend} from "@shared/utils/api/removeEntityInBackend";
import {loadEntityFromBackend} from "@shared/utils/api/loadEntityFromBackend";
import {loadListFromBackend} from "@shared/utils/api/loadListFromBackend";
import {fetchWithMessages} from "@shared/utils/api/fetchWithMessages";
import {filterAppointments} from "@routes/sessions/atoms/filterAppointments";
import {agendaSlotDatesManager} from "@routes/sessions/atoms/agendaSlotDatesManager";
import {filterSessions} from "@routes/sessions/atoms/filterSessions";
import {getFullSessionName} from "@shared/utils/sessionsUtilities";
import {EntitiesSelectors, LoadListParams} from "@utils/features/types";
import {sorter} from "@shared/utils/sorters";
import i18next from "i18next";

export const specialCategoriesFilterOptions = ["volunteering", "allTheRest"];

const validAvailability = (slot, direction, project) => {
  let availabilitySlots;

  if (slot.places[0] && slot.places[0].availabilitySlots) {
    availabilitySlots = slot.places[0].availabilitySlots.map((a) => ({
      start: dayjs(a.start),
      end: dayjs(a.end),
    }));
  } else {
    availabilitySlots = project.project.availabilitySlots.map((a) => ({
      start: dayjs(a.start),
      end: dayjs(a.end),
    }));
  }

  availabilitySlots.sort((a, b) => a.start - b.start); //asc
  const dayjsSlot = {...slot, start: dayjs(slot.start), end: dayjs(slot.end)};
  let out;
  for (const [index, availabilitySlot] of availabilitySlots.entries()) {
    if (dayjsSlot.start >= availabilitySlot.start && dayjsSlot.end <= availabilitySlot.end) {
      //in
      out = dayjsSlot;
      break;
    } else if (dayjsSlot.start < availabilitySlot.start && index === 0) {
      //trop tot en limite
      let outEnd = availabilitySlot.start.add(dayjsSlot.duration, "minute");
      out = {
        ...dayjsSlot,
        end: outEnd,
        start: availabilitySlot.start,
      };
      // console.log('break too early');
      break;
    } else if (dayjsSlot.end > availabilitySlot.end && index === availabilitySlots.length - 1) {
      //pas trop tard en limite
      let outStart = availabilitySlot.end.subtract(dayjsSlot.duration, "minute");
      out = {
        ...dayjsSlot,
        start: outStart,
        end: availabilitySlot.end,
      };
      // console.log('break too late');
      break;
    } else if (index !== availabilitySlots.length - 1) {
      const nextAvaibility = availabilitySlots[index + 1];
      if (dayjsSlot.end > availabilitySlot.end && dayjsSlot.start < nextAvaibility.start) {
        //entre deux
        let outEnd;
        if (direction === "backward") {
          outEnd = availabilitySlot.end.clone();
        } else if (direction === "forward") {
          outEnd = nextAvaibility.start.clone();
        }
        outEnd = outEnd.add(dayjsSlot.duration, "minute");
        out = {
          ...dayjsSlot,
          start: nextAvaibility.start,
          end: outEnd,
        };
      }
    }
  }
  return out;
};

const postponeSlotInPlacesOpenTimeRecursiv = (slot, project, direction) => {
  const existingSlots = project.slots.map((es) => ({
    ...es,
    start: dayjs(es.start),
    end: dayjs(es.end),
  }));
  const key = existingSlots.map((s) => s._id).indexOf(slot._id);

  const validAvabilityOnly = false;

  let validSlot = slot;
  if (validAvabilityOnly) {
    validSlot = validAvailability(slot, direction, project);
  }

  let postponeHitory;
  switch (direction) {
    case "backward":
      if (key !== 0) {
        let postponeCandidate = existingSlots[key - 1];
        if (postponeCandidate.end > validSlot.start) {
          let postponeCandidateStart = validSlot.start.subtract(
            postponeCandidate.duration,
            "minute"
          );
          postponeCandidate = {
            ...postponeCandidate,
            end: validSlot.start.clone(),
            start: postponeCandidateStart,
          };
        }
        postponeHitory = postponeSlotInPlacesOpenTimeRecursiv(
          postponeCandidate,
          project,
          direction
        );
        const lastHistory = postponeHitory[postponeHitory.length - 1];
        if (lastHistory.end > validSlot.start) {
          let validSlotEnd = lastHistory.end.add(validSlot.duration, "minute");
          validSlot = {
            ...validSlot,
            start: lastHistory.end.clone(),
            end: validSlotEnd,
          };
        }
        if (validAvabilityOnly) {
          validSlot = validAvailability(validSlot, "forward", project);
        }

        postponeHitory.push(validSlot);
      } else {
        if (validAvabilityOnly) {
          return [validAvailability(slot, direction, project)];
        } else {
          return [slot];
        }
      }
      break;
    case "forward":
      if (key !== existingSlots.length - 1) {
        let postponeCandidate = existingSlots[key + 1];
        if (postponeCandidate.start < validSlot.end) {
          let postponeCandidateEnd = validSlot.end.add(postponeCandidate.duration, "minute");
          postponeCandidate = {
            ...postponeCandidate,
            start: validSlot.end.clone(),
            end: postponeCandidateEnd,
          };
        }
        postponeHitory = postponeSlotInPlacesOpenTimeRecursiv(
          postponeCandidate,
          project,
          direction
        );
        const lastHistory = postponeHitory[postponeHitory.length - 1];
        if (lastHistory.start < validSlot.end) {
          let validSlotStart = lastHistory.start.subtract(validSlot.duration, "minute");
          validSlot = {
            ...validSlot,
            end: lastHistory.start.clone(),
            start: validSlotStart,
          };
        }
        if (validAvabilityOnly) {
          validSlot = validAvailability(validSlot, "backward", project);
        }
        postponeHitory.push(validSlot);
      } else {
        if (validAvabilityOnly) {
          return [validAvailability(slot, direction, project)];
        } else {
          return [slot];
        }
      }
      break;
    default:
  }
  return postponeHitory;
};

const postponeSlotInPlacesOpenTime = (rawCurrentSlot, session) => {
  let currentSlot = {
    ...rawCurrentSlot,
    start: dayjs(rawCurrentSlot.start),
    end: dayjs(rawCurrentSlot.end),
  };

  const existingSlots = session.slots.map((es) => ({
    ...es,
    start: dayjs(es.start),
    end: dayjs(es.end),
  }));

  // Choose the direction. If we don't know the ID of the slot (ie the slot is new),
  // let's say 'forward'
  const oldCurrentSlotIndex = existingSlots.map((s) => s._id).indexOf(currentSlot._id);

  let oldCurrentSlot;
  if (oldCurrentSlotIndex !== -1) {
    // If found, get the old slot data in the existing slots array
    oldCurrentSlot = existingSlots[oldCurrentSlotIndex];
  } else {
    // If not found (= it's a new added slot), then use the current slot as the old slot
    oldCurrentSlot = currentSlot;
  }

  let direction;
  if (currentSlot.start.isBefore(oldCurrentSlot.start)) {
    direction = "backward";
  } else if (currentSlot.end.isAfter(oldCurrentSlot.end)) {
    direction = "forward";
  }

  let postponeResult;
  if (direction) {
    postponeResult = postponeSlotInPlacesOpenTimeRecursiv(currentSlot, session, direction);
  } else {
    postponeResult = [currentSlot];
  }

  const out = [];
  for (const postponeSlot of postponeResult) {
    if (typeof postponeSlot !== "object") {
      throw Error("Found dirty values in the postpone recommandations");
    }
    const existingSlot = existingSlots.find((es) => es._id == postponeSlot._id);
    if (existingSlot != undefined) {
      if (
        existingSlot.start.diff(postponeSlot.start) !== 0 ||
        existingSlot.end.diff(postponeSlot.end) !== 0
      ) {
        out.push(postponeSlot);
      }
    } else {
      out.push(postponeSlot);
    }
  }
  return out;
};

export const displayInconsistencies = (sessionEditingState, projectId) =>
  // just check it with a little delay, cause it takes long
  setTimeout(
    () =>
      fetchWithMessages(`projects/${projectId}/sessions/checkInconsistencies`, {
        method: "POST",
        body: sessionEditingState,
      }).then((data) => displaySessionsInconsistenciesNotifications(data)),
    1500
  );

const sessionsAdapter = createCustomEntityAdapter({
  selectId: (el) => el._id,
  sortComparer: (a, b) => sorter.date(a.start, b.start),
});
const reducers = sessionsAdapter.reducers;

export const sessionsSlice = createSlice({
  name: "sessions",
  initialState: sessionsAdapter.getInitialState({
    init: {status: "idle"},
    editing: {},
    slotList: undefined,
  }),
  reducers: {
    addToList: (state, action) => {
      sessionsAdapter.addOne(state, action);
      state.slotList = [
        ...state.slotList,
        ...action.payload.slots.map((slot) => ({...slot, session: action.payload})),
      ];
    },
    updateInList: (state, action) => {
      sessionsAdapter.setOne(state, action);
      state.slotList = [
        ...state.slotList.filter((slot) => slot.session._id !== action.payload._id),
        ...action.payload.slots.map((slot) => ({...slot, session: action.payload})),
      ];
    },
    removeFromList: (state, action) => {
      sessionsAdapter.removeOne(state, action);
      state.slotList = state.slotList.filter((slot) => slot.session._id !== action.payload);
    },
    initContext: reducers.initContext,
    resetContext: (state, action) => {
      if (action.payload?.removeProject) {
        state.slotList = undefined;
      }
      reducers.resetContext(state, action);
    },
    initList: (state, action) => {
      reducers.initList(state, action);
      state.slotList = Object.values(state.entities)
        .map((session) => session.slots.map((slot) => ({...slot, session})))
        .flat();
    },
    selectEditingByIdFromList: (state, action) => {
      state.editing = state.entities[action.payload];
    },
    changeEditing: reducers.changeEditing,
    setEditing: reducers.setEditing,
  },
});

const asyncActions = {
  loadList:
    ({forceLoad, silent}: LoadListParams = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const projectId = state.currentProject.project._id;

      await loadListFromBackend(
        "sessions",
        projectId,
        state.sessions.init,
        () => dispatch(sessionsActions.initContext(projectId)),
        (data) => dispatch(sessionsActions.initList({list: data.list, project: projectId})),
        forceLoad,
        !silent
      );
    },
  loadEditing: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    return loadEntityFromBackend(
      "sessions",
      entityId,
      projectId,
      state.sessions.editing,
      () =>
        dispatch(
          sessionsActions.setEditing({
            _id: "new",
            start: state.currentProject.project.start,
            stewards: [],
            places: [],
            slots: [],
          })
        ),
      (data) => dispatch(sessionsActions.setEditing(data)),
      {
        notFoundAction: () =>
          OFFLINE_MODE && dispatch(sessionsActions.setEditing(state.sessions.entities[entityId])),
      }
    );
  },
  persist:
    (fieldsToUpdate?: any, checkInconsistencies = false, optimisticUpdate = false) =>
    async (dispatch, getState) => {
      const state = getState();
      const projectId = state.currentProject.project._id || fieldsToUpdate.project; // If no project id, fll back on the fields given

      // If some fields are given as argument, directly take this to update the registration
      const payload = fieldsToUpdate || {...state.sessions.editing};

      // Pseudo optimistic update for slot times to give an instant feeling in the agenda view:
      if (optimisticUpdate) {
        // If the session is new, put all slots ids to "new" as well to prevent display bugs
        if (payload._id === "new") {
          payload.slots = payload.slots?.map((slot) => ({...slot, _id: "new"}));
        }
        // Then, update or add the session to the list
        dispatch(sessionsActions.updateInList(payload));
      }

      // if asked, check the inconsistencies
      checkInconsistencies &&
        displayInconsistencies(state.sessions.editing, state.currentProject.project._id);

      return persistEntityInBackend(
        "sessions",
        {...payload, project: projectId},
        projectId,
        ({registrationsToUpdateIds, ...session}) => {
          dispatch(sessionsActions.addToList(session));
          console.log("remove after persist");
          // Finally, remove the "new" session and slots from the lists
          optimisticUpdate && dispatch(sessionsActions.removeFromList("new")); // Remove the "new" element from list that was created in case there was an optimistic update thing

          // Reload registrations
          dispatch(registrationsActions.loadList({forceLoad: true}));
        },
        ({registrationsToUpdateIds, ...session}) => {
          dispatch(sessionsActions.updateInList(session));

          // Reload registrations
          dispatch(registrationsActions.loadList({forceLoad: true}));
        }
      );
    },
  remove: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    // TODO refaire une passe sur toutes les .list
    // réadapter le removeEntityFromBackend

    await removeEntityInBackend(
      "sessions",
      entityId,
      projectId,
      sessionsSelectors.selectList(state),
      (_, {registrationsToUpdateIds}) => {
        dispatch(sessionsActions.removeFromList(entityId));
        dispatch(registrationsActions.loadList({forceLoad: true}));
      },
      true
    );
  },
  loadEditingHistory: (entityId) => async (dispatch, getState) => {
    const state = getState();
    const projectId = state.currentProject.project._id;

    !OFFLINE_MODE &&
      (await loadEntityFromBackend(
        "sessions",
        `${entityId}/history`,
        projectId,
        state.sessions.editing,
        null,
        (data) => dispatch(sessionsActions.setEditing({...state.sessions.editing, history: data}))
      ));
  },
  updateSlotToEditing: (newSlotData) => async (dispatch, getState) => {
    const state = getState();

    // Add duration to the slot data
    newSlotData.duration = dayjs(newSlotData.end).diff(dayjs(newSlotData.start), "minute");

    // Get all the slots of the current session in the redux state
    const currentSession = {...state.sessions.editing};
    const existingSlots = currentSession.slots.map((s) => ({...s}));

    try {
      // Get all the slots that need to be postponed and for each of them, update them with new start and end dates
      // This manages all the setup for the start and end dates
      const slotsPostponed = postponeSlotInPlacesOpenTime(newSlotData, state.sessions.editing);
      for (const slotPostponed of slotsPostponed) {
        let slotToUpdate = existingSlots.find((es) => es._id === slotPostponed._id);
        if (slotToUpdate) {
          slotToUpdate.start = slotPostponed.start.toISOString();
          slotToUpdate.end = slotPostponed.end.toISOString();
        }
      }
    } catch (error) {
      return;
    }

    // Add all the rest. Don't include the start and end dates as they could have been changed by the function above.
    const {start, end, ...dataToUpdate} = newSlotData;
    let slotToUpdateKey = existingSlots.findIndex((es) => es._id == newSlotData._id);
    existingSlots[slotToUpdateKey] = {
      ...existingSlots[slotToUpdateKey],
      ...dataToUpdate,
    };

    // Rebuild session
    currentSession.slots = existingSlots;

    // Compute the new start and end for the session based on the new data
    const sessionStartAndEnd = getSlotsStartAndEnd(
      currentSession.slots,
      state.currentProject.project.start
    );
    dispatch(sessionsActions.changeEditing({...currentSession, ...sessionStartAndEnd}));
  },
};

const sessionsAdapterSelectors = sessionsAdapter.getSelectors((state) => state.sessions);

export const sessionsSelectors: EntitiesSelectors<any, any> = {
  selectEditing: (state) => state.sessions.editing,
  selectList: sessionsAdapterSelectors.selectAll,
  selectById: sessionsAdapterSelectors.selectEntities,
  selectIsLoaded: (state) => state.sessions.init.status === "loaded",
  selectSlotsList: (state) => state.sessions.slotList,
};

// Memoized selector for slots list
sessionsSelectors.selectSlotsListForAgenda = createSelector(
  [
    sessionsSelectors.selectSlotsList,
    teamsSelectors.selectList,
    registrationsSelectors.selectList,
    (state) => currentProjectSelectors.selectProject(state).sessionNameTemplate,
  ],
  (slotList, teamsList, registrationsList, sessionNameTemplate) => {
    return slotList?.map((slot) => ({
      ...slot,
      id: slot._id,
      title:
        slotNumberString(slot) + getFullSessionName(slot.session, sessionNameTemplate, teamsList),
      startDate: slot.start,
      endDate: slot.end,
      location: getSessionElementsFromListInSlot("places", slot, (el) => el.name).join(", "),
      placeId: getSessionElementsFromListInSlot("places", slot, (el) => el._id),
      categoryId: slot.session.activity?.category?._id,
      stewardsNames: getSessionElementsFromListInSlot("stewards", slot, personName).join(", "),
      participantsNames: registrationsList
        ?.filter((r) => getSessionSubscription(r, slot.session))
        .map((r) => personName(r.user))
        .join(", "),
      stewardId: getSessionElementsFromListInSlot("stewards", slot, (el) => el._id),
      color: slot.session.activity?.category?.color,
      activityTags: slot.session.activity?.tags,
      sessionTags: slot.session.tags,
    }));
  }
);

sessionsSelectors.selectAgendaResources = createSelector(
  [
    placesSelectors.selectList,
    stewardsSelectors.selectList,
    categoriesSelectors.selectList,
    currentProjectSelectors.selectProject,
  ],
  (places, stewards, categories, currentProject) => {
    return [
      currentProject.usePlaces && {
        fieldName: "placeId",
        resourceName: "places",
        title: "Espaces",
        instances: places.map((place) => ({
          text: place.name,
          id: place._id,
          availabilitySlots: place.availabilitySlots,
          color: "transparent",
        })),
        allowMultiple: true,
        hasAvailabilities: true,
      },
      {
        fieldName: "stewardId",
        resourceName: "stewards",
        title: i18next.t("stewards:label_other"),
        instances: stewards.map((steward) => ({
          text: personName(steward),
          id: steward._id,
          availabilitySlots: steward.availabilitySlots,
        })),
        allowMultiple: true,
        hasAvailabilities: true,
      },
      {
        fieldName: "categoryId",
        resourceName: "category",
        title: "Catégories",
        instances: categories.map((category) => ({
          text: category.name,
          id: category._id,
          color: category.color, // Color given to the appointment slots on the page
        })),
        allowMultiple: false,
      },
    ].filter((el) => !!el);
  }
);

sessionsSelectors.selectAgendaFilteredAppointments = createSelector(
  [
    sessionsSelectors.selectSlotsListForAgenda,
    registrationsSelectors.selectList,
    viewSelectors.selectAgendaParams,
  ],
  (slots, registrations, agendaParams) =>
    filterAppointments({slots, registrations}, agendaParams)?.map(
      agendaSlotDatesManager.fromDataToDisplay
    )
);

sessionsSelectors.selectListFiltered = createSelector(
  [
    sessionsSelectors.selectList,
    registrationsSelectors.selectList,
    viewSelectors.selectAgendaParams,
    viewSelectors.selectSessionFilterPeriod,
  ],
  (sessions, registrations, agendaParams, filterPeriod) =>
    filterSessions({sessions, registrations}, agendaParams, filterPeriod)
);

export const sessionsReducer = sessionsSlice.reducer;

export const sessionsActions = {
  ...sessionsSlice.actions,
  ...asyncActions,
};
